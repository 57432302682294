<template>
  <div class="schedule">
    <Title :title="title" :description="description"></Title>
    <!-- {{dayList}} {{scheduleList}} -->
    <p v-if="dayList.length==0" class="isblank">敬请期待</p>
    <div class="content-wrapper" v-else>
      <section class="schedule-content">
        <div id="menu" class="pc" style="display: none;" v-if="1">
          <ul>
            <li v-for="(item,index) in dayList" :key="'m'+index">
              <a @click.prevent="toggleDay(index, $event)" :data-time="item.timeStamp" :class="item.timeStamp==selectedDate?'dianji current':'dianji'">
                DAY{{index+1}}
              </a>
            </li>
          </ul>
        </div>
        <div class="schedule-time">
          <div
            v-for="(item, index) in dayList"
            :class="`time-item ${item.timeStamp === selectedDate ? 'selected' : ''}`"
            :data-time="item.timeStamp"
            :key="'schedule_time_'+index"
            @click="toggleDay(index, $event)"
          >
            <h4>DAY{{ index + 1 }}</h4>
            <p>{{ item.time }} {{ item.week }}</p>
          </div>
        </div>
        <div class="schedule-day" ref="schedule" v-for="(scheduleItem, itemId, itemIndex) in scheduleList" :key="itemId">
          <h4 class="day-title">DAY{{ itemIndex + 1 }}</h4>
          <table class="schedule-item">
            <div class="time-container">
              <div class="time-list" v-for="(time, k) in Object.values(timeList)[itemIndex]" :key="k">
                <td class="time-title">
                  <!-- <img src="../assets/img/time.png" /> -->
                  <span class="fenhuichang">分会场</span>
                  <span class="line"></span>
                  <span class="shijian">时间</span>
                </td>
                <td class="time-header" v-for="(t, index) in time" :key="'schedule_day_'+index">
                  {{ t.split("-")[0] }}
                  <br />
                  -
                  <br />
                  {{ t.split("-")[1] }}
                </td>
              </div>
            </div>
            <div class="type-list">
              <div class="type-item" v-for="(daySchedule, j) in scheduleItem" :key="j">
                <tr class="item-container" v-for="(item, index) in daySchedule" :key="'schedule_title_'+index">
                  <!-- 出品人 -->
                  <td class="schedule-title" @click="!isDifference(itemIndex+1, j, index) ? toSubject(item.id) : ''">
                  <!-- <td class="schedule-title" @click="toSubject(item.id)"> -->
                    <h4>{{ item.venueName }}</h4>
                    <p>出品人：{{ item.name }}</p>
                    <!-- <p @click="item.producerId?toTeacher(item.producerId):''">出品人：{{ item.name }}</p> -->
                  </td>
                  <!-- 普通排课 -->
                  <slot v-if="!isDifference(itemIndex+1, j, index) && item.detail && item.detail.length>0">
                    <slot v-for="(schedule, i) in item.detail">
                      <!-- <td class="schedule-detail no-schedule" :key="'_'+i">敬请期待</td> -->
                      <td v-if="schedule=='敬请期待' || !schedule.courseId" class="schedule-detail no-schedule" :key="'_'+i">
                        <a>敬请期待</a>
                      </td>
                      <td v-else class="schedule-detail" :key="i">
                        <!-- 新标签页打开 -->
                        <a :href="courseUrl(schedule.courseId)" target="_blank">
                          <!-- @click="toCourse(schedule.courseId)" -->
                          <!-- {{schedule}} -->
                          <h4>{{ schedule.courseTitle }}</h4>
                          <div class="lecturer-list" v-for="(lecturer, id) in schedule.lecturers" :key="id">
                            <p class="name">{{ lecturer.name }}</p>
                            <p class="company">
                              <!-- {{ lecturer.company }}<br />{{ lecturer.position }} -->
                              {{ lecturer.company&&lecturer.company!=='无' ? lecturer.company : '' }}<br />
                              {{ lecturer.position&&lecturer.position!=='无' ? lecturer.position : '' }}
                              <!-- <slot v-if="lecturer.company==='无'&&lecturer.position==='无'">
                                {{ lecturer.description }}
                              </slot> -->
                            </p>
                          </div>
                        </a>
                      </td>
                    </slot>
                  </slot>
                  <!-- 特殊排课 -->
                  <slot v-else-if="isDifference(itemIndex+1, j, index) && scheduleDifference[setDifferenceKey(itemIndex+1, j, index)].courses && scheduleDifference[setDifferenceKey(itemIndex+1, j, index)].courses.length>0">
                    <!-- flex-item -->
                    <td class="schedule-detail schedule-difference link-url" v-if="scheduleDifference[setDifferenceKey(itemIndex+1, j, index)].linkUrl">
                      <a :href="scheduleDifference[setDifferenceKey(itemIndex+1, j, index)].linkUrl" target="_blank">点击报名本专场</a>
                    </td>
                    <td class="schedule-detail schedule-difference" v-for="(schedule, i) in scheduleDifference[setDifferenceKey(itemIndex+1, j, index)].courses" :key="'td_d_'+scheduleDifference[setDifferenceKey(itemIndex+1, j, index)].timeStamp+'_'+i">
                      <p class="time time-top">
                        {{ schedule.times[0] }}<slot v-if="schedule.times[1]"><br>-<br>{{ schedule.times[1] }}</slot>
                      </p>
                      <a>
                        <h4>
                          <!-- <p class="time">{{ schedule.times[0] }}<slot v-if="schedule.times[1]">-{{ schedule.times[1] }}</slot></p> -->
                          {{ schedule.courseTitle }}</h4>
                        <div class="lecturer-list">
                          <p class="name">
                            <slot v-for="(lecturer, id) in schedule.lecturers">
                              <slot v-if="id>0">/</slot>{{lecturer.name}}
                            </slot>
                          </p>
                          <p class="company">
                            <slot v-for="(lecturer, id) in schedule.lecturers">
                              <slot v-if="id>0">/</slot>{{lecturer.company}}
                            </slot>
                            <!-- <br/> lecturer.courseTitle -> 暂不需要显示讲师议题-->
                          </p>
                        </div>
                      </a>
                    </td>
                  </slot>
                  <!-- 敬请期待 -->
                  <div class="no-detail" v-else>
                    <slot v-if="!isDifference(itemIndex+1, j, index)">
                      <td class="schedule-detail no-schedule" v-for="num in 3" :key="'difference_'+itemIndex+'_'+j+'_'+index+'_'+num">
                        <a>敬请期待</a>
                      </td>
                      <td class="schedule-detail no-schedule" v-show="index === 1">敬请期待</td>
                    </slot>
                    <slot v-else>
                      <td class="schedule-detail no-schedule" v-for="num in scheduleDifference[setDifferenceKey(itemIndex+1, j, index)].length" :key="'difference_'+itemIndex+'_'+j+'_'+index+'_'+num">
                        <a>敬请期待</a>
                      </td>
                    </slot>
                  </div>
                </tr>
              </div>
            </div>
          </table>
        </div>
      </section>
      <section class="mob-schedule">
        <div class="mob-schedule-time">
          <div
            v-for="(item, index) in dayList"
            class="mob-time-item"
            :class="item.timeStamp == selectedDate ? 'selected' : ''"
            :data-time="item.timeStamp"
            :key="'schedule_day_mob_'+index"
            @click="toggleDay(index, $event)"
          >
            <div>DAY{{ index + 1 }}</div>
          </div>
        </div>
        <div v-for="(typeList, i, m_d_i) in scheduleList" :class="`mob-schedule-list ${i === selectedDate ? 'actived' : ''}`" :key="i">
          <div class="mob-type-list">
            <div
              class="mob-type-item"
              v-for="(type, j) in typeList"
              :key="j"
              :data-id="j"
              :class="j === selectedSchedule ? 'selected' : ''"
              @click="toggleSchedule"
            >
              {{ type[0] && type[0].venueName
              }}{{ type[1] && "/" + type[1].venueName }}
            </div>
          </div>
          <div v-for="(type, j) in typeList" :class="`mob-speech-wrapper ${j === selectedSchedule ? 'actived' : ''}`" :key="j">
            <div class="mob-speech-list" v-for="(s, k) in type" :key="k">
              <slot v-if="!isDifference(m_d_i+1, j, k)">
                <!-- <div class="mob-speech-item" @click="toCourse(speech.courseId)" :key="key"> -->
                <div class="mob-speech-item" v-for="(speech, key) in s.detail" :key="key">
                  <a :href="courseUrl(speech.courseId)" target="_blank">
                    <h4>{{ speech.courseTitle }}</h4>
                    <div class="mob-lecturer-list" v-for="(lecturer, l) in speech.lecturers" :key="l">
                      <p class="lecturer">
                        <span>{{ lecturer.name }}</span>
                        <!-- {{ lecturer.company }} {{ lecturer.position }} -->
                        {{ 
                          lecturer.company&&lecturer.company!=='无' ? lecturer.company : '' 
                        }} {{ 
                          lecturer.position&&lecturer.position!=='无' ? lecturer.position : '' 
                        }}
                      </p>
                    </div>
                    <p class="time">
                      <span class="glyphicon glyphicon-time"></span>
                      {{ speech.time }}
                    </p>
                  </a>
                </div>
              </slot>
              <slot v-else>
                <div class="mob-speech-item" v-for="(speech, key) in scheduleDifference[setDifferenceKey(m_d_i+1, j, k)].courses" :key="key">
                  <a>
                    <h4>{{ speech.courseTitle }}</h4>
                    <div class="mob-lecturer-list" v-for="(lecturer, l) in speech.lecturers" :key="l">
                      <p class="lecturer">
                        <span>{{ lecturer.name }}</span>
                        {{ 
                          lecturer.company&&lecturer.company!=='无' ? lecturer.company : '' 
                        }} {{ 
                          lecturer.position&&lecturer.position!=='无' ? lecturer.position : '' 
                        }}
                      </p>
                    </div>
                    <!-- <p class="time">
                      <span class="glyphicon glyphicon-time"></span>
                      {{ speech.times[0] }}-{{ speech.times[1] }}
                    </p> -->
                  </a>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </section>
    </div>
    <card-index v-if="$route.query.card" :_kind="'schedule'" :_info="scheduleSubject"></card-index>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Title from "@/components/Title";
import { sid, scheduledifference, getSpeechInfoNew, getScheduleInfo, getSueArr, getSueType, scheduleDifferenceId } from "@/utils/api.js";
import { types } from 'util';

const weekList = {
  0: "星期日",
  1: "星期一",
  2: "星期二",
  3: "星期三",
  4: "星期四",
  5: "星期五",
  6: "星期六",
};
const amTimeStr =
  "9:00-10:00,10:00-11:00,11:00-12:00,8:45-9:35,9:35-10:25,10:25-11:15,11:15-12:05";
const pmTimeStr = "13:30-14:30,14:30-15:30,15:50-16:50,16:50-17:50";
export default {
  name: "NewSchedule",
  components: {
    Title,
    CardIndex: () => import('../components/Card/Index.vue'),
  },
  data() {
    let _this = this;
    return {
      title: "大会日程",
      description: "年度技术创新及研发实践的架构案例",
      qd: '',
      selectedDate: "",
      selectedSchedule: "1", // 移动端选中的日程
      dayList: [], // 日期列表
      scheduleList: {}, // 日程列表
      scheduleSubject: {}, // 海报
      timeList: {},
      totalScheduleList: [],
      scheduleDifference: scheduledifference(_this.global.year),
    };
  },
  mounted() {
    this.qd = this.$route.query && this.$route.query.qd || '';
    this.getSpeech();
    this.getSchedule();
    if(this.scheduleDifference && typeof this.scheduleDifference==='object') {
      this.getDifference();
    }
  },
  watch: {
    dayList: {
      handler(_new, _old) {
        if(_new && _new.length>0)
        this.$nextTick(() => {
          $(window).off('scroll');
          this.menuScroll()
          window.addEventListener('resize', ()=>{
            $(window).off('scroll');
            if(this.$route.path && this.$route.path.includes('/schedule')){
              this.menuScroll()
            }
          })
        })
      },
      // immediate: true,
      deep: true,
    },
  },
  methods: {
    toggleDay(index, e) {
      this.selectedDate = e.currentTarget.dataset.time;
      this.selectedSchedule = "1";
      // window.scrollTo(0, this.$refs.schedule[index].offsetTop - 121);
      this.scrollSmoothTo(this.$refs.schedule[index].offsetTop - 121);
    },
    toggleSchedule(e) {
      this.selectedSchedule = e.target.dataset.id;
    },
    courseUrl(cid) {
      let url = `/2024sz/course?id=${cid}${this.qd.length>0?'&qd='+this.qd:''}`
      return url;
    },
    toCourse(e) {
      if(e)
        this.$router.push({ path: "/2024sz/course", name: "FourSZCourse", query: { id:e } });
        // query: { id: e.target.dataset.courseid }, // data-courseid
    },
    toTeacher(e) {
      this.$router.push({
        path:"/2024sz/teacher",
        name:"FourSZTeacher",
        query:{ id:e }
      });
    },
    toSubject(e) {
      this.$router.push({
        path:"/2024sz/subject",
        name:"FourSZSubject",
        query:{ id:e }
      });
    },
    // smooth
    scrollSmoothTo (position) {
      if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = function(callback, element) {
          return setTimeout(callback, 17);
        };
      }
      // 当前滚动高度
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // 滚动step方法
      var step = function () {
        // 距离目标滚动距离
        var distance = position - scrollTop;
        // 目标滚动位置
        // console.log('scrollSmoothTo ', position, scrollTop, distance, document.body.offsetTop);
        scrollTop = scrollTop + distance / 5;
        if (Math.abs(distance) < 1) {
          window.scrollTo(0, position);
        } else {
          window.scrollTo(0, scrollTop);
          requestAnimationFrame(step);
        }
      };
      step();
    },
    menuScroll: function () {
      let w_width = window.innerWidth;
      if(w_width > 767) {
        let w_height = window.innerHeight;
        $('#menu').css({ display: 'none' });
        this.showMenu(w_width, w_height);
        $(() => {
          $(window).scroll(() => {
            this.showMenu(w_width, w_height);
          });
        });
      }
    },
    showMenu(w_width, w_height) {
      if(this.$route.path.includes('/schedule')){
        let s_top = document.body.scrollTop || document.getElementsByTagName('body').scrollTop || window.pageYOffset;
        let header_height = document.getElementsByClassName('pc-wrapper')[0].offsetHeight;
        let item2 = this.$refs.schedule[1];
        if(item2){
          let h = item2.offsetTop - (w_height-header_height);
          // console.log('scroll ', $(window).scrollTop(), s_top);
          // console.log(h, this.selectedDate);
          if ($(window).scrollTop() > h) { // 1600
            $("#menu").fadeIn(500);
            this.selectedDate = this.dayList[1].timeStamp;
          } else {
            $("#menu").fadeOut(500);
            this.selectedDate = this.dayList[0].timeStamp;
          }
        }
      }
    },
    // 1. 日期 DAY1 2021.07.30 星期五
    // 2. 每天的日程 DAY1 日程
    // 跳转日程详情页需传入courseid
    // { 1627274400: {1:[{},{}],2:[{},{}]}}
    setDifferenceKey(day, groupid, index) {
      let period = index===0||index===1 ? ['am', 'pm'][index] : ''
      let key = `${this.global.year}_${day}_${groupid}_${period}`
      return key;
    },
    isDifference(day, groupid, index) {
      let flag = false;
      if(this.scheduleDifference && typeof this.scheduleDifference==='object') {
        let key = this.setDifferenceKey(day, groupid, index);
        let keys = Object.keys(this.scheduleDifference);
        if(keys.includes(key)) {
          flag = true;
        }
      }
      return flag;
    },
    getDifference() {
      let url = getSueType + scheduleDifferenceId;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log("difference ", res);
          if (res.errno == 0 && res.data) {
            let type = '';
            let typeList = [];
            for(let item of res.data) {
              type = item.typeName && typeof item.typeName==='string' && item.typeName.toLocaleLowerCase();
              if(type && type.includes('giac')) {
                typeList.push({id:item.id, name:type.split('#')[1]});
              }
            }
            this.getScheduleDifference(typeList);
          }
        })
    },
    getScheduleDifference(typeList) {
      for(let index in Object.keys(this.scheduleDifference)) {
        this.scheduleDifference[Object.keys(this.scheduleDifference)[index]].courses = [];
        this.$forceUpdate();
      }
      // console.log("difference ", typeList);
      let url = getSueArr + scheduleDifferenceId;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log("difference ", res);
          if (res.errno == 0 && res.data) {
            for(let typeItem of typeList) {
              if(this.scheduleDifference[typeItem.name]) {
                this.scheduleDifference[typeItem.name].courses = [];
                this.scheduleDifference[typeItem.name].timeStamp = new Date().getTime();
                let course = {}, lecturers=[], lecturer='', times=[], titles=[], title='';
                for(let index in res.data[0]) {
                  let course = res.data[0][index];
                  // console.log(course);
                  // schedule-difference
                  lecturers = course.content && course.content.length>0 && course.content.split(' /// ');
                  if(course.content_note && course.content_note.length>0) {
                    times = (course.content_note.split('scheduleTitle--')[0] || '').replace('time--','').replace(/\r\n/g,'').split('-');
                    // titles = (course.content_note.split('scheduleTitle--')[1] || '').replace(/^\r\n/,'').split('\r\n');
                  }
                  this.scheduleDifference[typeItem.name].courses.push({
                    courseTitle: course.title,
                    lecturers: [],
                    times: times,
                  });
                  for(let lindex in lecturers) {
                    lecturer = lecturers[lindex] || '';
                    // title = titles[lindex] || '';
                    if(lecturer.split(' || ')[0] && lecturer.split(' || ')[0].trim() !== '客户技术高层') {
                      this.scheduleDifference[typeItem.name].courses[index].lecturers.push({
                        name: lecturer.split(' || ')[0] || '',
                        company: lecturer.split(' || ')[1] || '',
                        position: lecturer.split(' || ')[2] || '',
                        // courseTitle: title, // 不需要显示讲师议题
                      });
                    }
                  }
                }
              }
            }
            this.$forceUpdate();
          }
        })
    },
    filterDayList(dateList) {
      Object.keys(dateList).map((item) => {
        this.dayList.push({
          timeStamp: item,
          time: dayjs(item * 1000).format("YYYY.MM.DD"),
          week: weekList[new Date(item * 1000).getDay()],
        });
      });
    },
    filterSpeechList(speechList) {
      const totalList = [];
      //   根据日期对日程进行分类
      for (const [key, value] of Object.entries(speechList)) {
        // console.log(key);
        // console.log(value);
        const { am: amList, pm: pmList } = value;
        // 遍历整合上午的日程
        for (const [type, val] of Object.entries(amList)) {
          // console.log(type);
          // console.log(val);
          for (const [k, v] of Object.entries(val)) {
            // console.log(k);
            // console.log(v);
            const timeStr = val.time[0] + "-" + val.time[1];
            if (k !== "time" && !Array.isArray(v)) {
              // 每个日程详情内添加会议时间和groupId
              v.timeStamp = key;
              v.time = timeStr;
              v.type = k;
              v.addType = "am";
              totalList.push(v);
            } else {
              // 添加上午的时间列表
              if (this.timeList[key] === undefined) {
                this.timeList[key] = { am: [], pm: [] };
              }
              if (
                this.timeList[key].am.indexOf(timeStr) <= -1 &&
                this.timeList[key].am.length < 3
              ) {
                this.timeList[key].am.push(timeStr);
              }
            }
          }
        }
        // 遍历整合下午的日程
        for (const [type, val] of Object.entries(pmList)) {
          // console.log(type);
          //   console.log(val);
          for (const [k, v] of Object.entries(val)) {
            // console.log(k);
            // console.log(v);
            const timeStr = val.time[0] + "-" + val.time[1];
            if (k !== "time" && !Array.isArray(v)) {
              v.timeStamp = key;
              v.time = timeStr;
              v.type = k;
              v.addType = "pm";
              totalList.push(v);
            } else {
              if (this.timeList[key] === undefined) {
                this.timeList[key] = { am: [], pm: [] };
              }
              if (this.timeList[key].pm.indexOf(timeStr) <= -1) {
                this.timeList[key].pm.push(timeStr);
              }
            }
          }
        }
        this.totalScheduleList = totalList;
        // console.log("totalScheduleList ", this.totalScheduleList);
        // console.log("this.scheduleList ", this.scheduleList);
        let times_arr = [];
        let times_data = [];
        for (const [key, value] of Object.entries(this.scheduleList)) {
          for (let k in value) {
            // console.log(value[k]);
            // "am"
            if (value[k][0]) {
              // 设置敬请期待
              // value[k][0].noDetail = [{}, {}, {}];
              // 敬请期待 + 日程
              value[k][0].detail = []
              times_arr = value[k][0].times.split(",");
              // console.log(times_arr);
              for(let t in times_arr){
                // value[k][0].detail.push('敬请期待');
                value[k][0].detail.push({courseTitle:'敬请期待', time:times_arr[t]});
                // time:this.global.formatTime(key,'Y.M.D')+' '+times_arr[t]
                times_data = this.totalScheduleList.filter(
                  (item) =>
                    item.timeStamp === key &&
                    item.type === k &&
                    item.addType === "am" && 
                    item.time == times_arr[t]
                )
                times_data.length>0 ? value[k][0].detail[t]=times_data[0] : '';
              }
              // console.log(value[k][0].detail)
              // 设置日程详情
              // value[k][0].detail = this.totalScheduleList.filter(
              //   (item) =>
              //     item.timeStamp === key &&
              //     item.type === k &&
              //     item.addType === "am"
              // );
            }
            // "pm"
            if (value[k][1]) {
              // 设置敬请期待
              // value[k][1].noDetail = [{}, {}, {}, {}];
              // 敬请期待 + 日程
              value[k][1].detail = []
              times_arr = value[k][1].times.split(",");
              // console.log(times_arr);
              for(let t in times_arr){
                // value[k][1].detail.push('敬请期待');
                value[k][1].detail.push({courseTitle:'敬请期待', time:times_arr[t]});
                times_data = this.totalScheduleList.filter(
                  (item) =>
                    item.timeStamp === key &&
                    item.type === k &&
                    item.addType === "pm" && 
                    item.time == times_arr[t]
                )
                times_data.length>0 ? value[k][1].detail[t]=times_data[0] : '';
              }
              // console.log(value[k][1].detail)
              // 设置日程详情
              // value[k][1].detail = this.totalScheduleList.filter(
              //   (item) =>
              //     item.timeStamp === key &&
              //     item.type === k &&
              //     item.addType === "pm"
              // );
            }
          }
        }

        // console.log("scheduleList ", this.scheduleList);
        // console.log(this.timeList);
      }
    },
    filterAllSchedule(list) {
      const obj = {};
      list.map((item) => {
        if (obj[item.date]) {
          if (obj[item.date][item.groupId]) {
            obj[item.date][item.groupId].push(item);
          } else {
            obj[item.date][item.groupId] = [item];
          }
        } else {
          obj[item.date] = {};
          obj[item.date][item.groupId] = [item];
        }
        // 添加 am/pm
        if (amTimeStr === item.times) {
          item.addType = "am";
        } else if (pmTimeStr === item.times) {
          item.addType = "pm";
        }
      });
      // console.log(list,obj);
      return obj;
    },
    filterSchedule(list) {
      let dayList=[], groupList=[]; // periodList=['am', 'pm'];
      for(let index in list) {
        if(!dayList.includes(list[index].date)) {
          dayList.push(list[index].date);
        }
        if(!groupList.includes(list[index].groupId)) {
          groupList.push(list[index].groupId);
        }
      }
      dayList = dayList.sort((a,b)=>{return a<b;});
      groupList = groupList.sort((a,b)=>{return a<b;});
      let schedule = {};
      for(let d in dayList) {
        schedule[d] = { 'datas': { 'am': {}, 'pm': {} }, 'date': dayList[d] };
        for(let g of groupList) {
          schedule[d]['datas']['am'][g] = {};
          schedule[d]['datas']['pm'][g] = {};
        }
      }
      let flag_date, flag_groupid, flag_period;
      for(let item of list) {
        flag_date = dayList.indexOf(item.date);
        flag_groupid = item.groupId;
        flag_period = this.getPeriod(item.times);
        if(flag_date>=0) {
          if(schedule[flag_date]['datas'][flag_period] && schedule[flag_date]['datas'][flag_period][flag_groupid]) {
            // schedule[flag_date][flag_groupid].push(item);
            schedule[flag_date]['datas'][flag_period][flag_groupid] = item;
          }
        }
      }
      // return schedule;
      this.scheduleSubject = schedule;
    },

    getSpeech: function () {
      // var url = "https://api.msup.com.cn/group/get-group-project/2682";
      let url = getSpeechInfoNew + sid(this.global.year);
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          if (res.errno == 0) {
            // console.log("getSpeech data: ", res.data);
            this.scheduleList = this.filterAllSchedule(res.data);
            // this.scheduleSubject = res.data;
            this.filterSchedule(res.data);
            // 
            // // scheduleList数据格式为 { 'dateNumber': { 'groupId': [ 'amSubjectObjectData', 'pmSubjectObjectData' ] } }
            // let dayList = Object.keys(this.baseData.base);
            // dayList = dayList.sort((a,b)=>{return a<b});
            // if(dayList[id] && this.baseData.base && this.baseData.base[dayList[id]] && typeof this.baseData.base[dayList[id]]==='object') {
            //   let data = { 'datas': { 'am': {}, 'pm': {} }, 'date': dayList[id] };
            //   for(let groupid in this.baseData.base[dayList[id]]) {
            //     data['datas']['am'][groupid] = {};
            //     data['datas']['pm'][groupid] = {};
            //     for(let item of this.baseData.base[dayList[id]][groupid]) {
            //       if(item.times) {
            //         if(this.getPeriod(item.times) == 'am') {
            //           data['datas']['am'][groupid] = item;
            //         }
            //         if(this.getPeriod(item.times) == 'pm') {
            //           data['datas']['pm'][groupid] = item;
            //         }
            //       }
            //     }
            //   }
            //   this.originData = data;
            // }
          }
        });
    },
    getPeriod(times) {
      let period = 'am';
      if(times && times.length > 0) {
        let hours = times.slice(0,2);
        if(!hours.includes(':') && !hours.includes('：')) {
          // let minutes = times.slice(3,5);
          if(hours > '12') {
            period = 'pm';
          }
        }
      }
      return period;
    },
    getSchedule: function () {
      // var url = "https://api.msup.com.cn/scheduling/get-schedule/2682";
      let url = getScheduleInfo + sid(this.global.year);
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          if (res.errno == 0) {
            // console.log("schedule ", res.data);
            this.totalScheduleList = this.filterSpeechList(res.data.datas);
            this.filterDayList(res.data.finishing);
            this.selectedDate = this.dayList[0] && this.dayList[0].timeStamp || '';
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule .isblank {
  width: 100%;
  // line-height: 75px;
  // font-family: PingFangSCBold;
  // font-weight: 500;
  // font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 90px;
  padding-bottom: 40px;
  border-bottom: 1px solid transparent;
}
@media (max-width: 767px) {
  .schedule-content {
    display: none;
  }
  .mob-schedule {
    display: block;
  }
}
@media (min-width: 768px) {
  .schedule-content {
    display: block;
  }
  .mob-schedule {
    display: none;
  }
}
@media (max-width: 750px) {
  .schedule .isblank {
    padding-top: 60px;
  }
}

// pc端样式
.schedule-content {
  padding-top: 45px;
  padding-bottom: 30px;
  #menu {
    width: auto;
    position: fixed;
    z-index: 601;
    bottom: 85px;
    right: 10px;
    margin-left: 400px;
    ul {
      margin-bottom: 0;
      li {
        a {
          display: block;
          margin: 5px 0 0 0;
          font-size: 14px;
          font-weight: bold;
          color: #333;
          width: 65px;
          height: 40px;
          line-height: 40px;
          text-decoration: none;
          text-align: center;
          border: 1px solid #0b70a4;
          background-color: rgba(255, 255, 255, 0.5);
          &:hover, &.current {
            color: #ffffff!important;
            border: 1px solid #003177;
            background: #003177;
          }
        }
      }
    }
  }
  .schedule-time {
    display: flex;
    justify-content: center;
    .time-item {
      margin-left: 60px;
      margin-right: 60px;
      padding-left: 50px;
      padding-right: 50px;
      width: 232px;
      height: 100px;
      color: #000;
      border: 1px solid #0b70a4;
      cursor: pointer;
      transition: all 0.3s;
      h4 {
        margin-bottom: 0;
        padding-top: 20px;
        // padding-top: 25px;
        font-size: 36px;
        font-weight: 500;
        color: inherit;
        text-align: center;
      }
      p {
        margin-bottom: 0;
        font-size: 15px;
        font-weight: 500;
        color: inherit;
        white-space: pre;
      }
      &.selected {
        color: #fff;
        background: #003177;
        p {
          font-weight: 600;
        }
      }
    }
  }
  .schedule-day {
    margin-left: auto;
    margin-right: auto;
    max-width: 1265px;
    .day-title {
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 40px;
      text-align: center;
      padding-top: 45px;
      padding-bottom: 35px;
    }
    .schedule-item {
      display: flex;
      .time-container {
        border-left: 1px solid #ddd;
        img {
          height: 94px;
        }
      }
      td {
        border: 1px solid #ddd;
        border-left: none;
        border-top: none;
      }
      .time-container .time-list:nth-of-type(1), 
      .type-list .type-item tr:nth-of-type(1) {
        td:nth-of-type(1) {
          border-top: 1px solid #ddd;
        }
      }
      .time-container {
        display: flex;
        flex-direction: column;
        .time-list {
          display: flex;
          flex-direction: column;
          .time-title {
            // padding-right: 42px;
            width: 145px;
            height: 96px;
            // height: 80px;
            position: relative;
            span {
              width: 100%;
              height: fit-content;
              line-height: 1;
              font-size: 16px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.85);
              display: block;
              position: absolute;
              &.fenhuichang {
                text-align: right;
                padding: 0 24px 0 0;
                top: 21px;
              }
              &.shijian {
                text-align: left;
                padding: 0 0 0 24px;
                bottom: 21px;
              }
              &.line {
                width: 76%;
                height: 1px;
                background-color: rgba(0, 0, 0, 0.85);
                top: 50%;
                left: 12%;
                transform: rotateZ(30deg);
              }
            }
          }
          .time-header {
            height: 280px;
            font-size: 16px;
            background: #fafbfc;
            color: #000;
            text-align: center;
            line-height: 24px;
            padding-left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
      .type-list {
        display: flex;
        .type-item {
          display: flex;
          flex-direction: column;
          .item-container {
            display: flex;
            flex-direction: column;
            &:first-child {
              height: 936px;
              // height: 920px;
            }
            &:last-child {
              height: 1216px;
              // height: 1200px;
            }
            .schedule-title {
              width: 186px;
              height: 96px;
              // height: 80px;
              text-align: left;
              color: #000;
              display: flex;
              flex-direction: column;
              justify-content: center;
              // padding-left: 14px;
              padding-left: 12px;
              // justify-content: top;
              // padding-left: 10px;
              // padding-top: 10px;
              cursor: pointer;
              h4 {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 0;
              }
              p {
                margin-top: 8px;
                // margin-top: 5px;
                margin-bottom: 0;
                font-size: 14px;
                cursor: pointer;
                font-weight: 600;
              }
            }
            .schedule-detail {
              flex-grow: 1;
              width: 186px;
              max-height: 280px;
              background: #fafbfc;
              text-align: center;
              font-size: 14px;
              color: #333;
              cursor: pointer;
              a {
                width: 100%;
                height: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
              h4 {
                width: 100%;
                height: 66px;
                color: #1f63af;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                text-align: center;
                padding: 0 10px;
                margin-bottom: 5px;
                // 省略
                word-break: break-all;
                word-wrap: break-word;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .lecturer-list {
                p {
                  margin-top: 0;
                  margin-bottom: 0;
                  text-align: center;
                }
                .name {
                  font-size: 14px;
                  color: #333;
                  font-weight: 700;
                  // margin-top: 10px;
                }
                .company {
                  height: 65px;
                  font-size: 14px;
                  color: #666;
                  padding-top: 5px;
                  padding-left: 5px;
                }
              }
              &.schedule-difference {
                &.link-url {
                  height: 40px;
                  max-height: 40px;
                  line-height: 40px;
                  background-color: #0138BC;
                  color: #ffffff;
                  font-weight: bold;
                  animation: blink 2.8s ease infinite;
                  a, a:hover, a:visited {
                    color: #ffffff;
                  }
                }
                @keyframes blink {
                  0% {
                    opacity: 1;
                    background-color: #0138BC;
                    // background-color: #00A0DC;
                  }
                  50% {
                    opacity: 0.9;
                    background-color: #00A0DC;
                    // background-color: #0138BC;
                  }
                  100% {
                    opacity: 1;
                    background-color: #0138BC;
                    // background-color: #00A0DC;
                  }
                }
                p.time {
                  display: block;
                  width: 100%;
                  line-height: 1;
                  font-size: 14px;
                  font-weight: 400;
                  color: #000;
                  margin-top: 0;
                  margin-bottom: 5px;
                  text-align: left;
                  &.time-top {
                    display: none;
                    width: fit-content;
                    height: 100%;
                    margin-bottom: 0;
                    text-align: center;
                  }
                }
                h4 {
                  height: auto;
                  max-height: max-content;
                  display: block;
                }
                .lecturer-list {
                  .company {
                    height: auto;
                  }
                }
                &.flex-item {
                  display: flex;
                  flex-wrap: nowrap;
                  align-items: baseline;
                  padding: 10px 0 10px 5px;
                  a {
                    padding: 0 5px;
                    justify-content: flex-start;
                  }
                  p.time {
                    display: none;
                    &.time-top {
                      display: block;
                    }
                  }
                  h4 {
                    padding: 0;
                    text-align: left;
                  }
                  .lecturer-list {
                    p {
                      text-align: left;
                    }
                    .company {
                      padding-left: 0;
                    }
                  }
                }
              }
            }
            .no-detail {
              background: #fafbfc;
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              .no-schedule {
                flex-grow: 1;
                font-size: 16px;
                // font-weight: bold;
                // text-align: center;
                // color: #2630B2;
              }
            }
          }
        }
      }
    }
  }
}

// 移动端样式
.mob-schedule {
  padding-top: 30px;
  .mob-schedule-time {
    display: flex;
    justify-content: center;
    .mob-time-item {
      border: 1px solid #003177;
      min-width: 135px;
      height: 60px;
      line-height: 60px;
      font-size: 30px;
      font-weight: 500;
      &.selected {
        color: #fff;
        background: #003177;
      }
    }
  }
  .mob-schedule-list {
    display: none;
    &.actived {
      display: block;
    }
    .mob-type-list {
      padding: 30px 3vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .mob-type-item {
        margin-bottom: 6px;
        background: rgb(249, 249, 249);
        color: #2c3e50;
        width: 46vw;
        height: 60px;
        line-height: 60px;
        padding-left: 4vw;
        padding-right: 4vw;
        font-size: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        &.selected {
          background: rgb(224, 224, 224);
        }
      }
    }
  }
  .mob-speech-wrapper {
    display: none;
    &.actived {
      display: block;
    }
    .mob-speech-item {
      margin: 0 auto;
      background: #f9f9f9;
      color: rgb(0, 0, 0);
      width: 94vw;
      padding: 20px;
      margin-bottom: 10px;
      text-align: left;
      a, a:hover, a:visited {
        color: inherit;
        text-decoration: none;
      }
      h4 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .mob-lecturer-list {
        .lecturer {
          margin-bottom: 5px;
          font-size: 14px;
          span {
            font-weight: 500;
            margin-right: 5px;
          }
        }
      }
      .time {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }
}
</style>